import React from "react";

const  Header = () => {
    return ( 
        <div>
            <h1 className="header">hi, i'm kaci</h1>
        </div>
        
     );
}
 
export default Header;